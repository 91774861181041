<template>
  <b-modal
    centered
    id="deliveryCountryNotFound"
    no-close-on-backdrop
    hide-footer
    size="lg"
    @hidden="clearData"
    dialog-class="registerCountryNotFound"
  >
    <template #modal-header="{ close }">
      <h6 class="text-primary">
        {{ $t("Modal.CountryNotFound.Title") }}
      </h6>
      <b-button class="close" @click="close()">
        <b-icon icon="x" />
      </b-button>
    </template>
    <template #default="{ hide }">
      <b-form>
        <b-row>
          <b-col cols="12">
            <p class="description">
              {{ $t("Modal.CountryNotFound.Description") }}
            </p>
          </b-col>
          <b-col cols="12" class="field">
            <label class="form-label">
              {{ $t("Modal.CountryNotFound.FromCountry") }}
            </label>
            <multiselect
              v-model="country"
              label="Name"
              track-by="Code"
              :options="countries"
              :multiple="true"
              :placeholder="$t('Modal.NoCountry.CountrySelectorPlaceholder')"
            />
          </b-col>
          <b-col cols="12" class="field">
            <label class="form-label">
              {{ $t("Modal.CountryNotFound.Name") }}
            </label>

            <b-input-group>
              <b-form-input type="text" v-model="name" class="input-pills" />
            </b-input-group>
          </b-col>
          <b-col cols="12" class="field">
            <label class="form-label">
              {{ $t("signIn.label.emailAddress") }}
            </label>
            <b-input-group>
              <b-form-input
                type="email"
                class="input-pills"
                v-model="email"
                :state="email ? isEmail : null"
              />
            </b-input-group>
          </b-col>
          <b-col cols="12" class="field">
            <b-button
              variant="primary"
              class="input-pills w-100 sendFormButton"
              @click="send()"
              :disabled="isFormValid"
            >
              {{ $t("Modal.CountryNotFound.Submit") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <div class="question-part">
        <p>
          {{ $t("Modal.NoCountry.Questions") }}
          <b-link :to="{ name: 'Contact', params: { type: 'contact' } }" @click="hide()">
            {{ $t("Contact.ContactUs.ContactUs") }}
          </b-link>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["countries"]),
    isEmail() {
      if (this.email) {
        if (this.emailFormat.test(this.email)) {
          return true;
        } else return false;
      } else return false;
    },
    isFormValid() {
      return !this.name || !this.email || !this.country || !this.isEmail;
    },
  },
  methods: {
    async send() {
      try {
        await axios.post("Basic/CantFindCountryBuyer", {
          CountryCodeList: this.country.map((item) => item.Code),
          CountryCode: this.$store.getters.country.Code,
          Name: this.name,
          Email: this.email,
        });
        this.clearData();
        this.$bvModal.hide("deliveryCountryNotFound");
        this.$store.dispatch("showToast", {
          content: this.$t("global.message.formIsSent"),
          type: "success"
        });
      } catch (error) {
        console.log(error);
      }
    },
    clearData() {
      Object.assign(this.$data, {
        value: null,
        options: ["list", "of", "options"],
        name: null,
        email: null,
        emailFormat: /\S+@\S+\.\S+/,
        country: null,
        searchCountry: ""
      });
    },
  },
  data() {
    return {
      value: null,
      options: ["list", "of", "options"],
      name: null,
      email: null,
      emailFormat: /\S+@\S+\.\S+/,
      country: [],
      searchCountry: "",
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style type="text/css">
.countrySelector .btn {
  border-radius: 50px !important;
  border: 1px solid #ced4da !important;
  height: 38px !important;
  text-align: left;
  line-height: 1 !important;
  padding: 0 0.75rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.countrySelector {
}
.countrySelector .btn:hover,
.countrySelector.show .btn {
  background-color: #fff;
  color: #000;
  border-color: #99c0f8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(34 119 239 / 25%);
}
</style>
<style lang="scss">
@import "@/assets/sass/custom.scss";
.registerCountryNotFound {
  max-width: 580px !important;
  .modal-content {
    padding: 1rem 2rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
  }
  .modal-header {
    padding: 1rem 0;
    margin-bottom: 1rem;
    h1 {
      margin: 0;
    }
    .close {
      width: 24px;
      height: 24px;
      background: #dbdbdb;
      border-radius: 100%;
      padding: 0 !important;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      margin: 0;
      svg {
        color: #777;
      }
    }
  }
  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #404040;
    margin-bottom: 1.5rem;
  }
  .question-part {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #404040;
    text-align: center;
  }
  .sendFormButton {
    height: 2.75rem;
    background-color: #006ac3;
    &:disabled {
      &:hover {
        background-color: #006ac3;
        border-color: #006ac3;
      }
    }
    &:hover {
      background-color: #00559d;
      border-color: #004e90;
    }
  }
  .field {
    margin-bottom: 1.5rem;
    label {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
  .multiselect {
    border-radius: 50px !important;
    .multiselect__tags {
      padding: 0.5rem 40px 0 1rem !important;
      min-height: 30px !important;
      border-radius: 50px !important;
      border: 1px solid $half-dark !important;
    }
    .multiselect__tag {
      border-radius: 50px !important;
    }
    &--active {
      .multiselect__tags {
        height: auto;
        border-radius: 1rem !important;
      }
      .multiselect__content-wrapper {
        margin-top: 0.25rem;
        border: 1px solid $half-dark !important;
        border-radius: 1rem !important;
        ul {
          li {
          }
        }
      }
    }
  }
}
</style>
